.right-panel-first-row-title{
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(90, 104, 114);
    font-family: 'Roboto-Regular' sans-serif;
    font-weight: normal;
    height: 12px;
    letter-spacing: 0.4px;
}

.right-panel-first-row-content{
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(0, 80, 117);
    font-family: 'Cairo-SemiBold' sans-serif;
    font-weight: 600;
    height: 24px;
    letter-spacing: 0.11px;
}

.right-panel-times-icon{
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: normal;
    height: 48px;
    letter-spacing: 0px;
    line-height: 15px;
    text-align: center;
}

.right-panel-times-icon-parent{
    box-shadow: 1px 1px 0px 0px rgba(21, 41, 53, 0.24);
    font-size: 14px;
    font-weight: normal;
    height: 48px;
    letter-spacing: 0px;
    line-height: 20px;
    text-align: center;
    width: 48px;
    /* position: absolute;
    left: -49px; */
    background: linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
}

.right-panel-hr{
    background: rgb(201, 217, 232);
    border-radius: 0px;
    height: 1px;
    width: 100%;
}

.right-panel-title{
    color: rgb(0, 80, 117);
    font-family: 'Cairo-Regular' sans-serif;
    font-size: 20px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0.15px;
    line-height: 24px;
}

.right-panel-description-parent{
    display: flex;
    justify-content: space-between;
}

.right-panel-description-parent span{
    color: rgb(0, 144, 209);
    font-family:' Roboto-Medium' sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 32px;
    letter-spacing: 0.2px;
    text-align: center;
}

.right-panel-description{
    color: rgb(157, 166, 173);
    font-family: 'Roboto-Regular' sans-serif;
    font-size: 14px;
    font-weight: normal;
    height: 144px;
    letter-spacing: 0.25px;
    line-height: 24px;
}

.right-panel-view-file{
    background: linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
    border-radius: 2px;
    height: 40px;
    width: 133px;
    box-shadow: 1px 1px 0px 0px rgba(21, 41, 53, 0.24);
    color: rgb(255, 255, 255);
    font-family: 'Roboto-Medium' sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 16px;
    text-align: center;
    border: none;
    outline: none;
}

.vapor-button{
    text-decoration: none;
    display: inline-block;
    outline: none;
    border-radius: 16px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    padding: 0px 16px;
    font-family: "Roboto" sans-serif;
    color: #5A6872;
    background: #D8D8D9;
    border: 1px solid #D8D8D9;
    margin: 5px 2px;
}

.vapor-button:hover{
    border: 1px solid #0090D1;
    background: #B9E6F6;
    color: #005075;
}

.edit-tags-label{
    color: rgb(21, 41, 53);
    font-family: 'Roboto-Regular' sans-serif;
    font-size: 14px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0.25px;
    line-height: 24px;
    cursor: pointer;
}

#toggle-tags-edit-mode {
    cursor: pointer;
}

.tags-label-parent{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tags-label-parent span{
    color: rgb(0, 80, 117);
    font-family:'Cairo-Regular' sans-serif;
    font-size: 20px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0.15px;
    line-height: 24px;
    cursor: pointer;
}

.disabled-chip {
    background-color: #bd2130;
    color: white;
}

.tags-edit-mode-save-changes{
    background: rgb(0, 144, 209);
    border-radius: 2px;
    border: 0;
    outline: 0;
    box-shadow: 1px 1px 0px 0px rgba(21, 41, 53, 0.24);
    color: rgb(255, 255, 255);
    font-family: 'Roboto-Medium' sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 32px;
    letter-spacing: 0.2px;
    line-height: 16px;
    text-align: center;
    width: 176px;
}

.tags-edit-mode-footer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgb(242, 245, 248);
    border-radius: 0px;
    height: 56px;
    width: 100%;
}

.tags-edit-mode-cancel{
    color: rgb(0, 144, 209);
    font-family: "Roboto-Medium" sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 16px;
    letter-spacing: 0.2px;
    line-height: 16px;
    text-align: center;
    width: 62px;
}

.times-icon-remove-tag{
    cursor: pointer;
}
.times-icon-remove-tag:hover{
    color: gray;
}
.zoom_main_wrapper {
    position: relative;
}
@media only screen and (max-width: 1920px) {
    .zoom_container {
        /* position: absolute; */
        /* top: 27rem; */
        top: 40px;
        left: 350px;
        /* left: 263px; */
        display: grid;
        grid-template-columns: 32px 80px 32px;
        width: 48%;
        background-color: #FFFFFF;
        align-items: center;
        justify-items: center;
        margin-top: 16px;

        z-index: 1;
        position: fixed;
        justify-content: end;
        padding: 1rem;
    }
}
@media only screen and (max-width: 1200px) {
    .zoom_container {
        width: 41%; 
    }
}
@media only screen and (max-width: 1054px) {
    .zoom_container {
        width: 38%; 
    }
}