.popover_options {
    width: 240px;
    border: 1px solid #DDE1E2;
    padding: 0;
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
}

.popover_options div {
    max-width: 240px;
    height: 40px;
    background: rgb(255, 255, 255);
}
.popover_options div:hover {
    background: rgb(242, 245, 248);
}
.open_button, .add_favorite, .rename_button, .archive_button, .download_button, .delete_spreadsheet, .delete_spreadsheet, .mb_info {
    padding: 8px 7px;
    cursor: pointer;
}
.rename_button, .archive_button, .download_button{
    color: rgb(21, 41, 53);
    font-family: 'Roboto-Regular' sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.25px;
}
.open_button{
    color: #152935;
    font-family: 'Roboto-Bold' sans-serif;
    font-size: 14px;
    /* font-weight: bold; */
    letter-spacing: 0px;
}
.add_favorite{
    color: #152935;
    font-size: 14px;
    font-family: 'Roboto-Regular' sans-serif;
    font-weight: normal;
    letter-spacing: 0.25px;
}
.delete_spreadsheet {
    border-top: 1px solid rgb(201, 217, 232);
    color: rgb(216, 40, 41);
    font-family: 'Roboto-Regular' sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.25px;
}
.mb_info {
    background-color: rgb(250, 251, 253) !important;
    color: rgb(90, 104, 114);
    font-family: 'Roboto-Regular' sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.25px;
    border-top: 1px solid rgb(201, 217, 232);
}

.modal .confirm_delete_modal .modal-content{
    width: 400px !important;
    height: 225px !important;
    background: rgb(255, 255, 255);
    border-radius: 0px;
}
.delete_modal_title {
    color: rgb(0, 80, 117);
    font-size: 24px;
    font-family: 'Cairo-SemiBold' sans-serif;
    font-weight: 600;
    letter-spacing: 0.18px;
    line-height: 32px;
}
.delete_modal_text {
    color: rgb(21, 41, 53);
    font-size: 16px;
    font-family: 'Roboto-Regular' sans-serif;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 24px;
}
.confirm_delete_modal .modal-content .modal-footer .modal_cancel {
    width: 86px;
    height: 40px;
    border: 1px solid rgb(0, 144, 209);
    border-radius: 2px;
    color: rgb(0, 144, 209);
    font-size: 16px;
    font-family: 'Roboto-Medium' sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 16px;
}
.modal_delete {
    width: 90px;
    height: 40px;
    background: rgb(216, 40, 41);
    border-radius: 2px;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-family: 'Roboto-Medium' sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 16px;
    box-shadow: 1px 1px 0px 0px rgba(21, 41, 53, 0.24);
    border: none;
}