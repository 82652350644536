.modal_titles_wrapper {
    border-bottom: 2px solid #0090D1;
    height: 46px;
    padding: 3px 0;
}
.settings_modal_title {
    color: rgb(0, 80, 117);
    font-size: 16px;
    font-family: 'Cairo-SemiBold' sans-serif;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.11px;
    line-height: 24px;
    margin: 0;
}
.settings_modal_subtitle {
    color: rgb(90, 104, 114);
    font-size: 10px;
    font-family: 'Roboto-Regular' sans-serif;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.33px;
    line-height: 16px;
    margin: 0;
}
.edit-profile-tabs{
    color: rgb(90, 104, 114);
    font-family: 'Cairo-SemiBold' sans-serif;
    font-size: 14px;
    font-weight: 600;
    height: 100%;
    text-align: center;
    border-radius: 0px;
}

.edit-profile-tabs-parent{
    border-bottom: 2px solid #F2F5F8;
}
.edit-profile-tabs-parent_active {
    background: #F2F5F8;
    border-bottom: 2px solid #0090D1;
    color: rgb(0, 144, 209);
    height: 100%;
}
.edit-profile-tabs-parent:hover{
    background: #F2F5F8;
    border-bottom: 2px solid #0090D1;
    color: rgb(0, 144, 209);
    height: 100%;
}
.modal-backdrop {
    background: #005075;
}

.label p:first-child {
    color: rgb(21, 41, 53);
    font-family: 'Roboto-Medium' sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 5px;
}
.label p:nth-child(2) {
    color: rgb(157, 166, 173);
    font-family: 'Roboto-Regular' sans-serif;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 12px;
    margin-bottom: 5px;
}
.profile_input {
    height: 40px;
    background: rgb(242, 245, 248);
    border: 1px solid rgb(201, 217, 232);
}
.password_input {
    height: 40px;
    background: rgb(242, 245, 248);
    border: 1px solid rgb(201, 217, 232);
}
.modal-body {
    background: white;
}
.profile_settings_modal {
    position: relative;
}
.profile_settings_modal .modal-content{
    position: absolute;
    top: -33vh !important;
    right: -48vw !important;
}
.profile_settings_modal .modal-content .modal-header .close{
    position: absolute;
    top: 16px;
    left: -49px;
    /* color: rgb(255, 255, 255); */
    width: 48px;
    height: 44px;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0px;
    line-height: 12px;
    background: linear-gradient(
        -26.56505117707799deg
        , rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
    box-shadow: 1px 1px 0px 0px rgba(21, 41, 53, 0.24);
    opacity: 1;
}
.profile_settings_modal .modal-content .modal-header .close span {
    color: rgb(255, 255, 255);
    opacity: 1;
    font-size: 16px;
    font-weight: 600;
}
.profile_settings_modal .modal-content .modal-body{
    height: 55vh;
}

@media only screen and (min-width: 1367px) {
    .profile_settings_modal .modal-content{
        top: -35vh !important;
    }
    .profile_settings_modal .modal-content .modal-body{
        height: 69vh !important;
    }
  }

.create-button:disabled {
    background: rgb(201, 217, 232);
    border-radius: 2px;
}