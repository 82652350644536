.login-top-Nav {
  background: rgb(21, 41, 53);
  border-radius: 0px;
  box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login-top-Nav {
  padding-left: 20px;
  padding-top: 5px;
  height: 100%;
  display: flex;
  align-items: center;
}
.login-top-Nav .data-text {
  color: rgb(0, 144, 209);
  font-family: "Cairo-SemiBold" sans-serif;
  font-size: 34px;
  font-weight: 600;
}
.login-top-Nav .manager-text {
  color: rgb(250, 251, 253);
  font-size: 34px;
  font-family: "Cairo-SemiBold" sans-serif;
  font-weight: 600;
  text-align: center;
}
.login_page_container {
  background-color: rgb(255, 255, 255);
  height: calc(100vh - 72px);
  width: 550px;
}
.login_container {
  background-color: #ffffff;
  padding-top: 5rem;
}
.teamsystem_id_container {
  padding: 4rem 3rem 1rem 3rem;
}
.login_title {
  color: rgb(0, 80, 117);
  font-size: 34px;
  font-family: "Cairo-SemiBold" sans-serif;
  font-weight: 600;
}
.access_team_button {
  margin-top: 1rem;
  width: 464px;
  height: 48px;
  background: linear-gradient(
    -26.56505117707799deg,
    rgb(0, 195, 234) 0%,
    rgb(0, 144, 209) 100%
  );
  border-radius: 2px;
  width: 100%;
  height: 48px;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-family: "Roboto-Medium" sans-serif;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.2px;
  line-height: 16px;
  box-shadow: 1px 1px 0px 0px rgba(21, 41, 53, 0.24);
  border: none;
  outline: none;
}
.access_team_button:hover {
  background-color: #00c3ea;
  border-radius: 2px;
}
.login_form_container {
  padding: 0 3rem;
}
input:focus {
  outline: none;
  border: 1px solid #0090d1;
}
.info_title {
  color: rgb(157, 166, 173);
  font-size: 16px;
  font-family: "Roboto-Regular" sans-serif;
  font-weight: normal;
  letter-spacing: 0.44px;
  line-height: 24px;
}
.horizontal_line_div {
  display: grid;
  grid-template-columns: 1fr 60px 1fr;
  text-align: center;
  margin-top: 1rem;
}
.oppure_text {
  font-size: 14px;
  font-weight: normal;
  font-family: "Roboto-Regular" sans-serif;
  color: rgb(90, 104, 114);
  margin-top: 4px;
}
.input_label {
  color: rgb(21, 41, 53);
  font-size: 14px;
  font-family: "Roboto-Medium" sans-serif;
  font-weight: 500;
}
.login_input {
  width: 100%;
  height: 40px;
  background: rgb(242, 245, 248);
  border: 1px solid rgb(201, 217, 232);
}
.login_input::placeholder {
  color: rgb(193, 193, 196);
  font-size: 14px;
  font-family: "Roboto-Regular" sans-serif;
  font-weight: normal;
  letter-spacing: 0.25px;
}
.forgot_password {
  cursor: pointer;
  color: rgb(0, 144, 209);
  font-size: 16px;
  font-family: "Roboto-Medium" sans-serif;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.2px;
  text-align: end;
}
.redirect_to_signup_Button {
  margin-top: 1rem;
  width: 100%;
  height: 48px;
  background-color: #ffffff;
  border: 1px solid rgb(0, 144, 209);
  border-radius: 2px;
  color: rgb(0, 144, 209);
  font-size: 16px;
  font-family: "Roboto-Medium" sans-serif;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.2px;
}

.no_borders_button {
  margin-top: 3px;
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  color: #0090d1;
  background-color: #ffffff;
  border: none;
  font-family: "Roboto-Medium" sans-serif;
}
.no_borders_button:hover {
  border: 1px solid #0090d1;
}

.redirect_to_signup_Button:hover {
  background-color: #0090d1;
  color: #ffffff;
  border: none;
}
.support_info_div {
  text-align: left;
  margin-top: 12rem;
}
.support_info {
  /* padding: 0 3rem; */
  margin: 0;
  color: rgb(157, 166, 173);
  font-size: 16px;
  font-family: "Roboto-Regular" sans-serif;
  font-weight: normal;
  letter-spacing: 0.44px;
}
.support_info_link {
  margin: 0;
  color: #7bc5e7;
  text-decoration: underline;
  font-size: 16px;
  font-family: "Roboto-Regular" sans-serif;
  font-weight: normal;
  letter-spacing: 0.44px;
}
@media only screen and (max-width: 1380px) {
  .login-top-Nav {
    height: 55px;
  }
  .login_page_container {
    height: calc(100vh - -100px);
  }
  .teamsystem_id_container {
    padding: 1rem 3rem 0rem 3rem;
  }
  /* .login_title {
    font-size: 20px;
  } */
  .support_info_div {
    margin-top: 1rem;
  }
  .support_info {
    padding: 0 1rem;
  }
  .support_info_link {
    padding: 0 1rem;
  }
}

.teamsystem-login_subtitle {
  width: 464px;
  height: 57px;
  color: rgb(157, 166, 173);
  font-size: 16px;
  font-family: "Roboto-Regular" sans-serif;
  font-weight: normal;
  letter-spacing: 0.44px;
  line-height: 24px;
}
.no_teamsystem_title {
  color: rgb(0, 80, 117);
  font-size: 24px;
  font-family: "Cairo-SemiBold" sans-serif;
  font-weight: 600;
  letter-spacing: 0.18px;
  line-height: 28px;
}
.access_without_TeamId_Button {
  width: 100%;
  height: 48px;
  border-radius: 0px;
  color: rgb(0, 144, 209);
  font-size: 16px;
  font-family: "Roboto-Medium" sans-serif;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.2px;
  border: none;
  background-color: #ffffff;
}
.access_without_TeamId_Button:hover {
  background-color: #f2f5f8;
}

.digital_logo {
  margin: 1rem 0;
}
@media only screen and (max-width: 1380px) {
  .no_teamsystem_title {
    font-size: 20px;
  }
}

.register_buttons_row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}

.login_page_container.recovery_page {
  height: calc(100vh - 183px);
}
