.main__right_side {
    background: #F2F5F8;
}

.main__wrapper {
    height: 100%;
    padding: 20px;
    background: #FFFFFF;
}
.main__wrapper .row {
    border-bottom: 2px solid rgb(0, 144, 209);
}
.main__wrapper .submenu_all_spreadsheets {
    display: grid;
    grid-template-columns: 30px 1fr;
}
.file_icon {
    height: 40px;
    width: 40px;
}
.submenu_all_spreadsheets div {
    align-self: center;
}
.submenu_all_spreadsheets .spreadsheet__title{
    color: rgb(0, 80, 117);
    font-family: 'Cairo-Regular' sans-serif;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0.15px;
    line-height: 24px;
    margin: 0;
}
.submenu_all_spreadsheets .spreadsheet__subtitle{
    color: rgb(90, 104, 114);
    font-family: 'Roboto-Regular' sans-serif;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 16px;
}
#searchInput {
    background: rgb(250, 251, 253);
    border-radius: 0px;
    border: 1px solid rgb(193, 193, 196);
    height: 40px;
    width: 308px;
}
#searchInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(216, 216, 217);
    font-family: 'Roboto-Regular' sans-serif;
    font-size: 14px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0.25px;
    line-height: 24px;
    width: 47px; /* Firefox */
}

#searchInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgb(216, 216, 217);
    font-family: 'Roboto-Regular' sans-serif;
    font-size: 14px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0.25px;
    line-height: 24px;
    width: 47px;
}

#searchInput::-ms-input-placeholder { /* Microsoft Edge */
    color: rgb(216, 216, 217);
    font-family: 'Roboto-Regular' sans-serif;
    font-size: 14px;
    font-weight: normal;
    height: 24px;
    letter-spacing: 0.25px;
    line-height: 24px;
    width: 47px;
}
.input-group{
    justify-content: flex-end;
}
.input-group button {
    background: linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
    border-radius: 0px 2px 2px 0px;
    height: 40px;
    width: 40px;
}
.input-group button:focus {
    background: rgb(201, 217, 232);
}

/* projects cards */
.projects_wrapper {
    height: 72vh;
    overflow-y: scroll;
}
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    background: #FFFFFF; 
}
::-webkit-scrollbar-thumb {
    background: rgb(193, 193, 196);
    border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
    background: rgb(193, 193, 196); 
}

.projects__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px 10px;
    justify-items: center;
    margin: 25px 10px;
}
.projects__container div{
    background: rgb(255, 255, 255);
    border-radius: 0px;
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.172);
    height: 144px;
    /* max-width: 352px; */
    width: 100%
}

@media only screen and (max-width: 1366px) {
    .projects__container {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media only screen and (max-width: 1125px) {
    .projects__container {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media only screen and (max-width: 665px) {
    .projects__container {
        grid-template-columns: 1fr;
    }
}

.project__wrapper {
    display: grid;
    grid-template-columns: 1fr 44px;
}

.project_right-buttons {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
}
.project_right-buttons button {
    color: rgb(0, 144, 209);
    background-color: rgb(242, 245, 248);
    font-family: 'FontAwesome5Pro-Regular' sans-serif;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 15px;
    text-align: center;
    border: none;
    outline: none;
}
.project_right-buttons button:nth-child(2){
    border-bottom: 2px solid #DEE8F1;
}
.project_right-buttons button svg {
    width: 16px;
}
.project_left-descriptions {
    display: grid;
    grid-template-columns: 0.2fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
        ". ."
        ". .";
    padding: 20px 3px;
    cursor: pointer;
}

.project_left-descriptions .project-fileIcon {
    text-align: center;
    justify-self: center;
    margin-top: 5px;
}

.project_left-descriptions h4 {
    color: rgb(0, 80, 117);
    font-family: 'Cairo-Regular' sans-serif;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0.15px;
    line-height: 24px;
    height: 73px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.project_left-descriptions .last_updated {
    color: rgb(90, 104, 114);
    font-family: 'Roboto-Regular' sans-serif;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 12px;
}

.last_updated span {
    color: rgb(157, 166, 173);
}