.Toastify__toast--info {
    border-radius: 0;
    border-top: 1px solid #005075;
    border-left: 1px solid #005075;
    border-bottom: 4px solid #005075;
    border-right: 1px solid #005075;
    background-color: white;
}
.Toastify__toast--success {
    border-radius: 0;
    border-top: 1px solid #09822A;
    border-left: 1px solid #09822A;
    border-bottom: 4px solid #09822A;
    border-right: 1px solid #09822A;
    background-color: white;
}
.Toastify__toast--error  {
    border-radius: 0;
    border-top: 1px solid #D82829;
    border-left: 1px solid #D82829;
    border-bottom: 4px solid #D82829;
    border-right: 1px solid #D82829;
    background-color: white;
}
.Toastify__toast--warning  {
    border-radius: 0;
    border-top: 1px solid #FDB927;
    border-left: 1px solid #FDB927;
    border-bottom: 4px solid #FDB927;
    border-right: 1px solid #FDB927;
    background-color: white;
}
.Toastify__close-button {
    color: #57666E;
}