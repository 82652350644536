.spreadsheet_top_info {
    margin: 0 !important;
    padding: 14px 14px 0 14px;
    border-bottom: 2px solid rgb(0, 144, 209);
}
/* .spreadsheet_top_info .col {
    border-bottom: 2px solid rgb(0, 144, 209);
} */
.icon_column {
    align-self: center;
    text-align: center;
    padding: 0 !important;
}
.title_column {
    padding: 0 5px !important;
}
.icon_column .clock_icon {
    color: rgb(0, 80, 117);
    font-size: 24px;
}
.all_spreadsheet_title {
    color: rgb(0, 80, 117);
    font-family: 'Cairo-Regular' sans-serif;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0.15px;
    line-height: 24px;
    margin: 8px 0 0 0 ;
}
.all_spreadsheet_subtitle {
    color: rgb(90, 104, 114);
    font-family: 'Roboto-Regular' sans-serif;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.4px;
    line-height: 16px;
}
.today_collapse_row {
    margin: 0 !important;
    border-bottom: 1px solid rgb(201, 217, 232);
}
.collapse_title {
    padding-left: "10px";
    color: rgb(0, 80, 117);
    font-family: 'Cairo-SemiBold' sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.11px;
    line-height: 24px;
}
.collapse_title svg {
    color: rgb(0, 80, 117);
}
.collapse_title .badge {
    border-radius: 12px;
    border: 1px solid rgb(255, 255, 255);
    background-color: #D4D4D6;
    height: 24px;
    width: 24px;
    line-height: 16px;
    color: #707A81;
}



.recent-projects-parent{
    padding: 20px;
    width: 100%;
}

.recent-projects-parent .collapse_wrapper{
    margin: 20px 0;
}

/* top navbar */

.top-navbar{
    background: rgb(21, 41, 53);
    border-radius: 0px;
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
    height: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top-navbar .logo{
    padding-left: 20px;
    padding-top: 5px;
}

.top-navbar .right-aligned-items{
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 3.5fr;

}

.fa-user{
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-weight: normal;
    height: 48px;
    letter-spacing: 0px;
    line-height: 15px;
    text-align: center;
    cursor: pointer;
}

.top-navbar .right-aligned-items span{
    color: rgb(255, 255, 255);
    font-family: 'Roboto-Medium' sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 16px;
    letter-spacing: 0.2px;
    line-height: 16px;
    width: 150px;
    padding-bottom: 30px;
    cursor: pointer;
}

.top-navbar .first-text{
    color: rgb(0, 144, 209);
    font-family: 'Cairo-SemiBold' sans-serif;
    font-size: 34px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    width: 69px;
}

.top-navbar .second-text{
    color: rgb(250, 251, 253);
    font-family: 'Cairo-SemiBold' sans-serif;
    font-size: 34px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 129px;
}

/* main content */

.main-content .main-panel-recent{
    background-color: #F2F5F8;
    height: 100%;
    padding: 14px 14px 0 14px;
}

.main-content .main-panel-recent .main-panel-content-recent{
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 20px;
}

.main-content .main-panel-recent .main-panel-content-recent .collapse-titles{
    cursor: pointer;
}

/* .recent-projects-parent {
    border: 10px solid rgb(242, 245, 248);
} */

/* popover */
.user_popover_header {
    background: #ffffff;
    color: rgb(0, 80, 117);
    font-size: 16px;
    font-family: 'Cairo-SemiBold' sans-serif;
    font-weight: 600;
    letter-spacing: 0.11px;
    line-height: 24px;
}