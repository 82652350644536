/* main content */
.modal {
    /* position: relative; */
}

.modal-dialog {
    position: absolute !important;
    top: 41%;
    left: 52%;
    transform: translate(-50%, -50%) !important;
}
.modal-backdrop {
    background-color: #005075 !important;
 }

.no_project_mainWrapper .row {
    border-bottom: none;
}
.titles_column {
    padding: 2vw 0 0 6vh !important;
}
.dashboard_noProjects_first-title{
    color: rgb(0, 80, 117);
    font-size: 34px;
    font-family: 'Cairo-SemiBold' sans-serif;
    font-weight: 600;
    line-height: 40px;
    /* color: rgb(90, 104, 114);
    font-family: 'Cairo-Bold' sans-serif;
    font-size: 42px;
    font-weight: bold;
    letter-spacing: 0.31px;
    line-height: 48px;
    text-align: center;
    max-width: 665px;
    width: 100%;
    margin: auto; */
}

.dashboard_noProjects_second-title{
    color: rgb(21, 41, 53);
    font-size: 16px;
    font-family: 'Roboto-Regular' sans-serif;
    font-weight: normal;
    letter-spacing: 0.44px;
    line-height: 24px;
}

.create_button{
    width: 152px;
    height: 48px;
    background: linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
    border-radius: 2px;
    box-shadow: 1px 1px 0px 0px rgba(21, 41, 53, 0.24);
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-family: 'Roboto-Medium' sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 16px;
    border: none;
    box-shadow: 1px 1px 0px 0px rgba(21, 41, 53, 0.24);
    /* margin-top: 15px; */
    outline: none;
}

.no-projects-parent{
    display: flex;
    justify-content: center;
    align-items: center;
}

.no_project_image {
    width: 100%;
    max-width: 447px;
    height: auto;
    padding: 7vh 1vw;
}
@media only screen and (max-width: 1366px) {
    .no_project_image {
        max-width: 350px;
        padding: 4vh 1vw;
    }
}

@media only screen and (min-width: 1920px) {
    .no_project_image {
        padding: 10vh 1vw;
    }
    .no-projects-parent div {
        padding-top: 13vh;
    }
}
.modal .modal-content{
    border-radius: 0px;
    width: 500px!important;
    /* height: 413px!important; */
}

.modal-header{
    border-bottom: 2px solid rgb(0, 144, 209) ;
}

.modal-footer{
    background-color: rgb(242, 245, 248);
}

.modal-title{
    color: rgb(0, 80, 117);
    font-family: 'Cairo-SemiBold' sans-serif;
    font-size: 24px;
    font-weight: 600;
    height: 32px;
    letter-spacing: 0.18px;
    line-height: 32px;
    width: 428px;
}

.modal-body{
    padding: 20px;
}

.modal-body .name{
    color: rgb(21, 41, 53);
    font-family: 'Roboto-Medium' sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 5px;
}

.modal-body small{
    color: rgb(157, 166, 173);
    font-family: 'Roboto-Regular' sans-serif;
    font-size: 12px;
    font-weight: normal;
    height: 5px;
    letter-spacing: 0.4px;
}

.modal-body .spreadsheet-name{
    background: rgb(242, 245, 248);
    border: 1px solid rgb(201, 217, 232);
    height: 40px;
    outline: none;
    width: 100%;
}

button.close span{
    color:rgb(0, 144, 209);
}

.create-button{
    background: linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
    border-radius: 2px;
    height: 40px;
    width: 160px;
    border: none;
    outline: none;
    box-shadow: 1px 1px 0px 0px rgba(21, 41, 53, 0.24);
    color: rgb(255, 255, 255);
    font-family: 'Roboto-Medium' sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 16px;
    text-align: center;
}

.discard-button{
    border-radius: 2px;
    border: 1px solid rgb(0, 144, 209);
    height: 40px;
    width: 86px;
    color: rgb(0, 144, 209);
    font-family: 'Roboto-Medium' sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 16px;
    text-align: center;
}

button:focus{
    outline: none;
}