.teamsystem_container {
  margin: auto;
  width: 60%;
  padding: 3rem 10rem;
}
.ts_title {
  font-weight: 400;
  color: #777;
  text-align: center;
}
.ts_linear_gradient {
  height: 9px;
  background-image: linear-gradient(
    to right,
    #5db2d8,
    #1473ac,
    #bb1c6a,
    #ec964c,
    #faca4a
  );
  border-radius: 5px;
  margin: 2rem 0;
}
.ts_email_input {
  width: 100%;
  height: 46px;
  border-radius: 6px;
  font-size: 18px;
  border-color: rgb(178, 178, 178);
}
.ts_buttons_row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}
.ts_green_button {
  background-color: #347ab7;
  color: #fff;
  border: none;
  outline: none;
  height: 40px;
  border-radius: 5px;
}
.ts_blue_button {
  background-color: #5cb85b;
  color: #fff;
  border: none;
  outline: none;
  height: 40px;
  border-radius: 5px;
}
.ts_bold_text {
  margin-top: 1rem;
  font-size: 11px;
  font-weight: 700;
  color: #333;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.ts_paragraph {
  margin-top: 1rem;
  font-size: 11px;
  color: #333;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.ts_checkbox_label {
    margin-left: 10px;
    font-size: 11px;
    color: #333;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}