/* .auth-content-parent {
  height: calc(100vh - 50px);
} */
.container {
  height: 33vh;
  width: 29vw;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pass_div_container {
    padding: 0 2rem;
}

.main_title {
  color: rgb(0, 80, 117);
  font-size: 34px;
  font-family: "Cairo-SemiBold" sans-serif;
  font-weight: 600;
}
.light_subtitle {
  color: rgb(157, 166, 173);
  font-size: 16px;
  font-family: "Roboto-Regular" sans-serif;
  font-weight: normal;
  letter-spacing: 0.44px;
}
.blueButton {
  width: 100%;
  height: 48px;
  background: linear-gradient(
    -26.56505117707799deg,
    rgb(0, 195, 234) 0%,
    rgb(0, 144, 209) 100%
  );
  border-radius: 2px;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-family: "Roboto-Medium" sans-serif;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.2px;
  box-shadow: 1px 1px 0px 0px rgba(21, 41, 53, 0.24);
  outline: none;
  border: none;
  margin-top: 1rem;
}
.text_icon_div {
    display: grid;
    grid-template-columns: 1fr 40px;
    align-items: center;
}

@media only screen and (max-width: 1380px) {
  .container {
    height: 45vh;
    width: 35vw;
  }
}
