/* top navbar */

.top-navbar{
    background: rgb(21, 41, 53);
    border-radius: 0px;
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
    height: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top-navbar .logo{
    padding-left: 20px;
    padding-top: 5px;
    height: 100%;
    display: flex;
    align-items: center;
}

.top-navbar .right-aligned-items{
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 3.5fr;
    align-items: center;

}
.right-aligned-items i {
    width: 48px;
    height: 100%;
    text-align: center;
}
.right-aligned-items .fa-power-off{
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-weight: normal;
    /* height: 64px; */
    letter-spacing: 0px;
    line-height: 15px;
    text-align: center;
    /* width: 64px; */
    padding-top: 6px;
}
.right-aligned-items .fa-user{
    color: rgb(255, 255, 255);
    font-size: 24px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 15px;
    text-align: center;
    padding-top: 20px;
    cursor: pointer;
}

.top-navbar .right-aligned-items span{
    display: grid;
    color: rgb(255, 255, 255);
    font-family: 'Roboto-Medium' sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 16px;
    letter-spacing: 0.2px;
    line-height: 16px;
    width: 75px;
    padding-bottom: 30px;
    padding-left: 10px;
    cursor: pointer;
    /* padding-top: 12px; */
}
.top-navbar .right-aligned-items span .user_email {
    color: rgb(193, 193, 196);
    font-family: 'Roboto-Regular' sans-serif;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0.33px;
}

.top-navbar .first-text{
    color: rgb(0, 144, 209);
    font-family: 'Cairo-SemiBold' sans-serif;
    font-size: 34px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    width: 69px;
}

.top-navbar .second-text{
    color: rgb(250, 251, 253);
    font-family: 'Cairo-SemiBold' sans-serif;
    font-size: 34px;
    font-weight: 600;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 129px;
}
#langselector {
    height: fit-content;
    width: 45px;
    align-self: center;
    background: #152935;
    color: #ffffff;
    border: none;
    outline: none;
    padding-bottom: 4px;
}

/* left sidebar */

.main-content{
    height: calc(100vh - 66px);
}

.left-sidebar{
    height: 100%;
}

.left-sidebar .content{
    padding: 20px 5px;
    height: 100%;
}

.left-sidebar .content p{
    color: rgb(90, 104, 114);
    font-family: 'Roboto-Regular' sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 24px;
}

.left-sidebar .create-new-project-button{
    background: linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
    border-radius: 2px;
    height: 48px;
    width: 100%;
    color: white;
    outline: none;
    border: none;
}

.left-sidebar .content .hr{
    background: rgb(201, 217, 232);
    border-radius: 0px;
    height: 1px;
}

.left-sidebar .content .menu-items{
    /* width: 100%;
    margin: auto; */
    
    margin-top: 5px;
    padding: 0 11px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

    /* max-width: 272px;
    width: 100%;
    margin: auto; */

    margin: 0;
    flex-direction: column;
    justify-content: center;

    
}

.left-sidebar .content .menu-items div:nth-child(2) {
    padding-top: 5px;
}

.left-sidebar .content .active{
    padding: 0 11px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(222, 240, 247);
    border-radius: 0px;
    color: rgb(0, 144, 209);
    font-family: 'Roboto-Medium' sans-serif;
    font-weight: 500;
    border-left: 2px solid #0090D1;
    line-height: 16px;
    margin: 0;
    flex-direction: column;
    justify-content: center;
}
.left-sidebar .content .menu-items div:nth-child(2) {
    /* padding-top: 8px; */
    padding: 6px 0 0 10px;
}
.left-sidebar .content .menu-items.active div:nth-child(2) {
    /* padding-top: 8px; */
    padding: 8px 0 0 5px;
}
.left-sidebar .content .disable{
    pointer-events: none;
}
.left-sidebar .content .disable  div{
    color: #DDE7F0;
    pointer-events: none;
}

.left-sidebar .content .menu-items:hover{
    cursor: pointer;
}

/* main content */

.main-content .main-panel{
    overflow: hidden;
    background-color: #F2F5F8;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: auto;
    padding: 14px 14px 0 14px;
}

.main-content .main-panel .main-panel-content{
    width: 100%;
    height: 100%;
    background-color: white;
}

.create_new {
    background: linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
    border-radius: 2px;
    border: none;
    outline: none;
    height: 48px;
    width: 100%;
    margin-bottom: 15px;
    box-shadow: 1px 1px 0px 0px rgba(21, 41, 53, 0.24);
    color: rgb(255, 255, 255);
    font-family: 'Roboto-Medium' sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 16px;
    text-align: center;
}

/* popover */
.user_popover .popover {
    top: 20px !important;
    left: -30px !important;
    right: 0;
}
.user_popover .popover .arrow {
    left: 190px !important;
}
.user_popover .popover .popover-inner .popover-body {
    height: 19vh;
}
@media only screen and (min-width: 1367px) {
    .user_popover .popover .popover-inner .popover-body {
        height: 14vh;
    }
}
.user_popover .popover .popover-inner .popover-body .row:nth-child(2) {
    margin-top: 2vh !important;
}
#user_popover_header {
    background: #ffffff;
    color: rgb(0, 80, 117);
    border-bottom: 1px solid rgb(201, 217, 232);
    font-size: 16px;
    font-family: 'Cairo-SemiBold' sans-serif;
    font-weight: 600;
    letter-spacing: 0.11px;
    line-height: 24px;
}
.popover_user_icon {
    color: rgb(0, 144, 209);
    font-size: 24px;
    font-weight: solid;
    height: 48px;
    letter-spacing: 0px;
    line-height: 15px;
    text-align: center;
    width: 48px;
}
.popover_username {
    color: rgb(21, 41, 53);
    font-size: 16px;
    font-family: 'Roboto-Medium' sans-serif;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 16px;
    margin: 0;
}
.popover_email {
    color: rgb(90, 104, 114);
    font-size: 10px;
    font-family: 'Roboto-Regular' sans-serif;
    font-weight: normal;
    letter-spacing: 0.33px;
    line-height: 16px;
    margin: 0;
}
.popover_modify_button{
    background: #ffffff;
    height: 40px;
    width: 100%;
    color: rgb(0, 144, 209);
    font-size: 16px;
    font-family: 'Roboto-Medium' sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 16px;
    border: none;
}
.popover_exit_button {
    background-color: rgb(216, 40, 41) !important;
    border-radius: 2px;
    height: 40px;
    width: 100%;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-family: 'Roboto-Medium' sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 16px;
    box-shadow: 1px 1px 0px 0px rgba(21, 41, 53, 0.24);
    border: none;
}
.modify_icon {
    margin-right: 12px;
    width: 16px;
    height: 16px;
    color: rgb(0, 144, 209);
    text-align: center;
    line-height: 15px;
}