
.top-navbar{
    background: rgb(21, 41, 53);
    border-radius: 0px;
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
    height: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.top-navbar .logo{
    padding-left: 20px;
    padding-top: 5px;
    height: 100%;
    display: flex;
    align-items: center;
}

.error_page_wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
    min-height: 93vh;
}
@media only screen and (max-width: 1400px) {
    .error_page_wrapper {
        min-height: 90vh;
    }
  }
  @media only screen and (max-width: 1094px) {
    .error_page_wrapper {
        grid-template-columns: 2fr 1.5fr;
    }
  }
.error_image_wrapper{
    background-color: rgb(242,245,248);
    text-align: center;
}
.error_401_image{
    max-width: 695px;
    width: 100%;
    height: auto;
    padding: 17vh 8vw;
}

@media only screen and (max-width: 1366px){
    .error_401_image{
        padding: 12vh 13vw;
    }
}


.error_texts_wrapper {
    background-color: #FAFAFD;
}
.teamsystem_logo_container{
    text-align: right;
    padding: 20px;
}
.teamsystem_logo {
    width: 100%;
    max-width: 197px;
    height: auto;
}
.error_text_container{
    padding: 20px;
}
.error_text_title{
    color: rgb(0, 80, 117);
    font-size: 40px;
    font-family: "Cairo-SemiBold" sans-serif;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 54px;
}
.error_text_time{
    color: rgb(90, 104, 114);
    font-size: 18px;
    font-family: 'Roboto-Regular' sans-serif;
    font-weight: normal;
    letter-spacing: 0px;
}
.try_again_text {
    color: rgb(0, 0, 0);
    font-size: 24px;
    font-family: "Roboto-Regular" sans-serif;
    font-weight: normal;
    letter-spacing: 0px;
}
.return_home_button {
    width: 234px;
    height: 40px;
    background: linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%);
    border-radius: 2px;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-family: "Roboto-Medium" sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 16px;
    box-shadow: 1px 1px 0px 0px rgba(21, 41, 53, 0.24);
    border: none;
    outline: none;
}

.info_container {
    display: grid;
    grid-template-columns: 40px 1fr;
    border-left: 5px solid rgb(0, 80, 117);
    margin: 17vh 20px 20px 20px;
    height: fit-content;
}
.light_on_icon{
    color: rgb(0, 80, 117);
    width: 32px;
    margin-top: 6px;
    margin-left: 10px;
    /* align-self: center; */
}
.info_title{
    color: rgb(0, 80, 117);
    font-size: 16px;
    font-family: "Cairo-SemiBold" sans-serif;
    font-weight: 600;
    letter-spacing: 0px;
}
.info_details{
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-family: 'Roboto-Regular' sans-serif;
    font-weight: normal;
    letter-spacing: 0px;
}


@media only screen and (max-width: 1366px){
    .error_text_container{
        padding: 0 20px;
    }
    .teamsystem_logo {
        max-width: 176px;
    }
    .error_text_title{
        line-height: 37px;
        font-size: 30px;
    }
    .error_text_time {
        font-size: 16px;
    }
    .try_again_text{
        font-size: 20px;
    }
    .info_container{
        margin-top: 10vh;
    }
    .info_details{
        font-size: 13px;
    }
}