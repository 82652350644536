#gc-designer-container div.spreadWrapper {
    height: 100vh
}

.ribbon{
    height: 66px;
}

.top-panels, .spreadWrapper{
    margin-top: 10px;
    z-index: 1;
}

.contentList .ribbon-button-group-horizontal .ribbon-button-item{
    padding: 7px 15px;
}

.collapsedRibbon, .ribbon-panelgrouplabel-container .ribbon-button-item{
    display: none;
}

.ribbon-panel-groups:last-child .ribbon-panel-group-content .ribbon-button-group-container .ribbon-button-group button {
    display: flex;
    justify-content: center;
}

.ribbon-panel-groups:last-child .ribbon-button-item-text {
    display: none;
}

.ribbon-panel-groups:last-child .ribbon-custom-triangle2 {
    display: none;
}

.gc-ribbon-bar.ribbon-panel-groups{
min-height: 66px;
height: 66px;
}

/*.ribbon-bigbutton {*/
/*    display: flex;*/
/*    justify-content: center !important;*/
/*    align-items: center;*/
/*    min-height: 16px !important;*/
/*}*/

/*.ribbon-button-shapes{*/
/*    margin-top: 10px;*/
/*}*/

/*.ribbon-bigbutton .ribbon-button-item-text {*/
/*    display: none !important;*/
/*}*/

/*.ribbon-bigbutton .ribbon-custom-triangle2 {*/
/*    position: relative;*/
/*    left: 22px;*/
/*    bottom: 20px;*/
/*    transform: scale(1.5);*/
/*}*/
.gc-ribbon-bar .ribbon-button-item.ribbon-bigbutton .ribbon-button-item-icon{
    background-size: 24px;
}
.contentList .ribbon-control-dropdown.gc-dropdown-ribbon.gc-designer-root.en.gc-sjsdeisnger-popup {
    top: 58% !important
}
.gc-ribbon-bar .ribbon-button-item[data-buttontype~="dropdown"] .ribbon-button-hover-item.vertical[dropdown-buttontype~="dropdown"] {
    display: flex;
    align-items: center;
}
.gc-ribbon-bar .ribbon-button-item[data-buttontype~="dropdown"] .ribbon-button-hover-item.vertical[dropdown-buttontype~="dropdown"]:hover {
    height: 23px;
    width: 23px;
}
.gc-ribbon-bar .ribbon-button-item .ribbon-button-item-icon{
    height: 15px;
    width: 15px;
}
.bottom-panels{
    display: none;
}

.ribbon-panel-group{
    border-right: 1px solid rgb(201, 217, 232)!important;
}

.ribbon-button-checkbox{
    margin-top: 10px;
}

.gc-ribbon-bar .ribbon-navigation{
    display: none;
}

.gc-formulaBar .mainBar .splitter{
    display: none;
}

.gc-formulaBar .mainBar .btn-group{
    border:none;
    margin-top: 3px;
}

.gc-formulaBar .mainBar .btn-group .cancel-btn{
    padding: 0px 10px;
}

/*file z index */

.spreadsheet-files-parent{
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0!important;
}

.file-info-right{
    background: rgb(255, 255, 255);
    border-radius: 0px;
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
    z-index: 2;
    height: 94vh;
}

.file-viewer{
    background: rgb(255, 255, 255);
    border-radius: 0px;
    box-shadow: 0px 2px 8px 0px rgba(21, 41, 53, 0.32);
    max-height: 94vh;
    overflow-y: auto;
    z-index: 3;
    height: 94vh;
    display: flex;
    justify-content: center;
    /* overflow-x: hidden; */
}

.file-viewer::-webkit-scrollbar {
    border-radius: 4px;
    /* height: 320px; */
    width: 8px;
}

.loading-parent-div {
    width: 100%;
    height: 100vh;
    z-index: 10000;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-viewer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(193, 193, 196);
}

.file-viewer::-webkit-scrollbar-thumb {
    background-color: rgb(193, 193, 196);
    border-radius: 4px;
}

.gc-sjsdesigner-dialog{
    /* height: 96vh!important; */
    height: fit-content !important;
    top: 5px!important;
    right: 0px!important;
    left: initial!important;
    background-color: #FFFFFF;
}

.gc-sjsdesigner-dialog .dialog-footer{
    background-color: #FFFFFF;
}

.gc-sjsdesigner-dialog .dialog-content{
    background-color: #FFFFFF;
}

.gc-dialog-overlay{
    background-color: #005075!important;
}


/* .dialog-titlebar{
    position: relative;
}
.dialog-titlebar .dialog-titlebar-close {
    position: fixed;
    top: 0;
    right: 35rem;
    background-color: lightblue;
} */
.gc-sjsdesigner-dialog .dialog-titlebar  {
    height: 49px;
    border-bottom: 2px solid rgb(0, 144, 209);
}
/* .dialog-titlebar {
    height: 46px;
    border-bottom: 2px solid rgb(0, 144, 209);
} */
.gc-sjsdesigner-dialog .dialog-titlebar .dialog-titlebar-close {
    position: absolute;
    top: 0;
    right: 100%;
    background-color: rgb(0, 144, 209);
    width: 48px;
    height: 48px;
    color: rgb(255, 255, 255) !important;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0px;
    line-height: 20px;
    box-shadow: 1px 1px 0px 0px rgba(21, 41, 53, 0.24);
}
.gc-sjsdesigner-dialog .dialog-titlebar .dialog-titlebar-close:hover{
    background-color: rgb(0, 144, 209);
}
.gc-sjsdesigner-dialog .dialog-titlebar .dialog-titlebar-close div {
    color: #ffffff
}
/* .gc-sjsdesigner-dialog .dialog-titlebar button {
    position: fixed;
    top: 0;
    right: 35rem;
    background-color: rgb(132, 149, 155);
} */
.dialog-titlebar .dialog-titlebar-title {
    color: rgb(0, 80, 117);
    font-size: 16px;
    font-family:'Cairo-SemiBold' sans-serif;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.11px;
    line-height: 24px;
}
.gc-list-control-item {
    height: 40px;
}
.gc-list-control-item.gc-list-control-selected-item {
    /* width: 276px; */
    height: 40px;
    background: rgb(222, 240, 247);
    border-radius: 0px;
}
.gc-flex-component div{
    color: rgb(0, 80, 117);
    font-size: 16px;
    font-family: 'Cairo-SemiBold' sans-serif;
    font-weight: 600;
    letter-spacing: 0.11px;
    line-height: 24px;
}
.gc-sjsdesigner-dialog .dialog-footer {
    margin-top: 30rem;
    height: 56px;
    background-color: #f2f5f8;
}
@media only screen and (max-width: 1400px) {
    .gc-sjsdesigner-dialog .dialog-footer {
        margin-top: 10rem;
    }
  }
.dialog-footer button:nth-child(1) {
    /* margin-top: 11rem; */
    width: 141px;
    height: 40px;   
    border-radius: 0px;
    background-color: #ffffff;
    border: 2px solid rgb(0, 144, 209);
    color: rgb(0, 144, 209);
    font-size: 16px;
    font-family: 'Roboto-Medium' sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 16px;
}
.dialog-footer button:nth-child(1) span {
    display: none;
}
.dialog-footer button:nth-child(1)::after {
    content: 'Cancel';
}
.dialog-footer button:nth-child(2){
    /* margin-top: 11rem; */
    width: 142px;
    height: 40px;
    background: rgb(0, 144, 209);
    border-radius: 2px;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-family: 'Roboto-Medium' sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 16px;
    box-shadow: 1px 1px 0px 0px rgba(21, 41, 53, 0.24);
}
.dialog-footer button:nth-child(2) span {
    display: none;
}
.dialog-footer button:nth-child(2)::after {
    content: 'Insert';
}

#file-content{
    width: 100%;
    height: 100%;
}

#TooltipExample, .spreadsheet-viewer, .react-grid-Container, .react-grid-Canvas{
    width: 100%;
    height: 100%;
}

.line-style-editor {
    overflow-y: scroll;
    overflow-x: hidden;
}
.gc-line-style-editor-container {
    border: none;
}
.label {
 margin-top: 20px;   
}
.gc-flexcontainer {
    margin: 0 !important;
}